import { ChildInputType } from '@/app/components/FormFields/FormFields';
import moment from 'moment';
import { OptionType } from '@/app/components/Trainings/types';
import translate from '@/app/utils/translate';

const GLOBAL: any = window;

export type EmployeeStatus = 'NEW' | 'PENDING' | 'PASSIVE' | 'ACTIVE' | 'ENDED';
export type EmployeeSummaryData = {
  fEmpId: number;
  fEmpStatus: EmployeeStatus;
  fEmpFirstName: string;
  fEmpLastName: string;
  fEmpGivenNames: string;
  fEmpPersonNumber: string;
  fEmpEmplId: number;
  fEmplStartDate: string;
  fEmpManagerId: number;
  fEmpUnit: number;
  fEmpPosition: string;
  fEmpProfPictureId: string;
  fEmpPhoneWork: string;
  fEmpPhoneMob: string;
  fEmpEmailWork: string;
  fEmpHasAccount: boolean;
};

export interface LegalEntity {
  fLegalEntityAddress1: string;
  fLegalEntityAddress2: string;
  fLegalEntityCity: string;
  fLegalEntityCode: string;
  fLegalEntityCountry: string;
  fLegalEntityId: number;
  fLegalEntityName: string;
  fLegalEntityPOBox: string;
  fLegalEntityPhone: string;
  fLegalEntityPostalCode: string;
  fLegalEntityState: string;
}

export type UnitOrgTreeItem = {
  id: number;
  parentId: number | '#';
  unitId: number;
  name: string;
  unitType: string;
};

export const getLoginData = () => {
  return GLOBAL.jsonloginData;
};

/** Get emp pic id. If empl of emp is ended, return emp pic id with '12345678_' + empId */
export const getEmpPicId = (employeeId: number) => {
  if (employeeId === -1) {
    return 'unknown';
  } else {
    return GLOBAL.jsonAllEmp.hasOwnProperty(employeeId)
      ? GLOBAL.jsonAllEmp[employeeId].fEmpProfPictureId
      : '12345678_' + employeeId;
  }
};

export const getEmployeeName = (
  employeeId: number,
  alternateFormat?: boolean,
  includeNumber?: boolean,
) => {
  if (employeeId === -1 || !GLOBAL.jsonAllEmp[employeeId]) {
    return '';
  }

  if (alternateFormat) {
    return `${GLOBAL.jsonAllEmp[employeeId].fEmpFirstName} ${GLOBAL.jsonAllEmp[employeeId].fEmpLastName}`;
  }

  if (includeNumber) {
    return (
      GLOBAL.jsonAllEmp[employeeId].fEmpLastName +
      ', ' +
      GLOBAL.jsonAllEmp[employeeId].fEmpFirstName +
      '(' +
      GLOBAL.jsonAllEmp[employeeId].fEmpPersonNumber +
      ')'
    );
  }
  return (
    GLOBAL.jsonAllEmp[employeeId].fEmpLastName +
    ', ' +
    GLOBAL.jsonAllEmp[employeeId].fEmpFirstName
  );
};

export const getEmployeePosition = (employeeId: number) => {
  const employee = GLOBAL.jsonAllEmp[employeeId];
  if (employeeId === -1 || !employee) {
    return '';
  }
  const jsonPos = employee.fEmpPosition;

  return jsonPos || '';
};

export const getLegalEntity = (legalEntityId: string) => {
  const legalEnt = GLOBAL.jsonLegalEntities.find((item: LegalEntity) => {
    return item.fLegalEntityId.toString() === legalEntityId;
  });

  return legalEnt;
};

export const toPascalCase = (str: string) => {
  return str
    .split(' ')
    .map(function(word: string) {
      // Only upper case the first char and lowercase the rest.
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(' ');
};

declare var jsonEnumConf: any;
export const getEnumGroups = (): any[] => {
  return jsonEnumConf;
};

export type Enum = {
  groupName?: string;
  code: string;
  description?: string;
  name: string;
  details?: string;
  seq?: number;
  tags?: string[];
  countries?: ChildInputType[] | string[];
  isActive?: boolean;
  inputType?: ChildInputType;
  extCode?: string;
  enumWarn?: boolean;
  orgUnits?: ChildInputType[] | string[];
  type?: string;
  editableByEmp?: boolean;
};

export const getEnums = (group: string = ''): Enum[] => {
  group = group.toUpperCase();
  if (group === '' || !GLOBAL.jsonEnumALL[group]) {
    return [];
  }
  return GLOBAL.jsonEnumALL[group];
};

export const mapBooleanToYesNo = (booleanValue: boolean): string => {
  return booleanValue === true ? translate.t('laYes') : translate.t('laNo');
};

declare var strLang: string;
export const updateEnums = (group: string = '') => {
  if (group === '') {
    return;
  }
  group = group.toUpperCase();
  GLOBAL.jsUpdateJSON(
    `d/json/enum/${encodeURIComponent(group)}/${strLang}`,
    `jsonEnumALL.${group}`,
  ).done((data: any) => {
    GLOBAL.jsonEnumALL[group] = data;
  });
};

export const updateEmpDocumentData = (empId: number) => {
  GLOBAL.jsUpdateJSON(
    `/d/json/employee/${empId}/file?includeReadOnly=true`,
    'jsonEmpDocumentData',
  ).done(() => {
    GLOBAL.jsReadEmpDocument();
  });
};

declare var jsonCompanyData: any;
export const getCompanyCountryCode = () => {
  return jsonCompanyData.fComCountry;
};
export const getCompanyData = () => {
  return jsonCompanyData;
};
export const getCompanyDefaultHours = () => {
  return {
    workDayHours: jsonCompanyData.config.fComTmWorkdayLengthHours,
    maxCumulativeHours: jsonCompanyData.config.fComTmMaxCumulativeHours,
    minCumulativeHours: jsonCompanyData.config.fComTmMinCumulativeHours,
    blockAfterDays: jsonCompanyData.config.fComTmPossibleEditingDays,
  };
};
export const updateCompanyData = (hours: any) => {
  jsonCompanyData.config.fComTmWorkdayLengthHours = hours.workDayHours;
  jsonCompanyData.config.fComTmMaxCumulativeHours = hours.maxCumulativeHours;
  jsonCompanyData.config.fComTmMinCumulativeHours = hours.minCumulativeHours;
  jsonCompanyData.config.fComTmPossibleEditingDays = hours.blockAfterDays;
};
export const getCompanyLanguages = (): {
  fComLanguageCode: string;
  fComLanguageIsDefault: boolean;
  fComLanguageName: string;
}[] => {
  return jsonCompanyData.languages;
};

declare var iHRAdmin: boolean;
export const isHRAdmin = () => {
  return iHRAdmin;
};

declare var iHRAdminLimited: boolean;
export const isHRAdminLimited = () => {
  return iHRAdminLimited;
};

declare var iHRSalary: boolean;
export const isHRSalary = () => {
  return iHRSalary;
};

declare var iHRPeople: boolean;
export const isHRPeople = () => {
  return iHRPeople;
};

declare var iHRPeopleDev: boolean;
export const isHRPeopleDev = () => {
  return iHRPeopleDev;
};

declare var iHR: boolean;
export const isHR = () => {
  return iHR;
};

declare var iManager: boolean;
export const isManager = () => {
  return iManager;
};

declare var iAltManager: boolean;
export const isAltManager = () => {
  return iAltManager;
};

declare var iEmployee: boolean;
export const isEmployee = () => {
  return iEmployee;
};

declare var iRecruiter: boolean;
export const isRecruiter = () => {
  return iRecruiter;
};

declare var iDManager: boolean;
export const isDManager = () => {
  return iDManager;
};

export const getMyEmployeeUnitId = (empId: any) => {
  return GLOBAL.jsonAllEmp[empId].fEmpUnit;
};

export type Country = {
  code: string;
  name: string;
  description: string;
};

declare var jsonCountries: Country[];
declare var jsonCompanyCountries: string[];
export const getCompanyCountries = (): Country[] => {
  return jsonCountries.filter(
    (c: Country) => jsonCompanyCountries.indexOf(c.code) !== -1,
  );
};

export interface OrganizationData {
  fOrgUnitName: string;
  fOrgUnitNumber: string;
  fOrgUnitUrl: string;
  fOrgUnitType: string;
  fOrgUnitResponsibleHrEmps: number[];
  fOrgUnitAvail: string;
  fOrgUnitContainingTrees: number[];
  fOrgUnitCreated: string;
  fOrgUnitLoc: number;
  fOrgUnitManager: number;
  fOrgUnitPos: number[];
  fOrgUnitId: number;
}
declare var jsonOrgUnitData: [];
export const getCompanyUnits = () => {
  return jsonOrgUnitData.map((unit: OrganizationData) => {
    return {
      label: unit.fOrgUnitName + ' - ' + unit.fOrgUnitNumber,
      code: unit.fOrgUnitId,
    };
  });
};

export const findSubUnits = (
  initialSelection: OptionType[],
  unitAudienceOptions: OptionType[],
  unitOrgTree: UnitOrgTreeItem[],
  includeSubUnits: boolean,
) => {
  if (
    !unitAudienceOptions ||
    !unitOrgTree ||
    !includeSubUnits ||
    !initialSelection
  ) {
    return initialSelection;
  }

  const findSubUnitsBasedOnParent = (currentUnit: OptionType): OptionType[] => {
    const foundUnits = unitOrgTree
      .filter(unitOrgTreeItem => unitOrgTreeItem.parentId === currentUnit.value)
      .map(unitOrgTreeItem => ({
        value: unitOrgTreeItem.unitId,
        label: unitOrgTreeItem.name,
      }));

    if (!foundUnits.length) {
      return [];
    }

    return foundUnits.reduce(
      (acc: OptionType[], itm) => [...acc, ...findSubUnitsBasedOnParent(itm)],
      foundUnits,
    );
  };

  const includedSubunits: OptionType[] = initialSelection.reduce(
    (acc, itm) => [...acc, ...findSubUnitsBasedOnParent(itm)],
    initialSelection,
  );

  return includedSubunits
    .filter((inclSubUnit, index, filteredSubUnits) => {
      return (
        filteredSubUnits
          .map(filteredSubUnit => filteredSubUnit.value)
          .indexOf(inclSubUnit.value) === index
      );
    })
    .map(unit => ({ ...unit, isFixed: true }))
    .sort();
};

export const getCompanyUnitsByCodeArray = (id: any) => {
  if (id.value) {
    return {
      label: id.label,
      value: id.value,
    };
  }

  const foundUnit = jsonOrgUnitData.find(
    (unit: OrganizationData) => unit.fOrgUnitId.toString() === id.toString(),
  ) as OrganizationData;

  if (!foundUnit) {
    return {
      label: '',
      value: -1,
    };
  }

  return {
    label: foundUnit.fOrgUnitName + ' - ' + foundUnit.fOrgUnitNumber,
    value: foundUnit.fOrgUnitId,
  };
};

export const getCompanyCountryNameByCode = (code: string): string => {
  return jsonCountries.find((country: Country) => country.code === code).name;
};

export const getMyTeams = (
  includeMyIndirectTeams?: boolean,
): Array<ChildInputType> => {
  if (!GLOBAL.jsonPrimaryUnitData) {
    return [];
  }
  if (GLOBAL.iHR || GLOBAL.iManager || GLOBAL.iDManager) {
    const allTheUnitsIManage = [...GLOBAL.aMyManUnits, ...GLOBAL.aMyHRUnits];
    // Include the units I am alternate/direct manager
    if (includeMyIndirectTeams) {
      GLOBAL.jsonPrimaryUnitData.forEach((unit: any) => {
        const {
          ALT_MANAGER = [],
          DEPUTY_MANAGER = [],
        } = unit.fOrgUnitResponsibleEmps;
        if (
          ALT_MANAGER.includes(GLOBAL.iLoginId) ||
          DEPUTY_MANAGER.includes(GLOBAL.iLoginId)
        ) {
          allTheUnitsIManage.push(unit.fOrgUnitId);
        }
      });
    }

    if (allTheUnitsIManage.length === 0) {
      return [];
    }

    return GLOBAL.jsonPrimaryUnitData
      .filter((d: any) => allTheUnitsIManage.indexOf(d.fOrgUnitId) >= 0)
      .map((d: any) => ({
        label: d.fOrgUnitName,
        value: d.fOrgUnitId,
        type: 'UNIT',
        unitEmployees: getUnitMembers(d.fOrgUnitId, true),
      }));
  }
  return [];
};

export const getTeamMembers = (teamId: any) => {
  let results: any = [];
  const allEmployees = GLOBAL.jsonAllEmp;
  const keys = Object.keys(allEmployees);
  keys.forEach(key => {
    if (
      allEmployees[key].fEmpStatus === 'ACTIVE' &&
      allEmployees[key].fEmpUnit === parseInt(teamId, 10)
    ) {
      results.push(allEmployees[key]);
    }
  });
  return results;
};

export const getUnitMembers = (orgUnit: number, filter: boolean = false) => {
  let results: any = [];
  const allEmployees = GLOBAL.jsonAllEmp;
  const keys = Object.keys(allEmployees);
  keys.forEach(key => {
    if (
      allEmployees[key].fEmpStatus === 'ACTIVE' &&
      allEmployees[key].fEmpUnit === orgUnit
    ) {
      if (filter) {
        results.push({
          label:
            allEmployees[key].fEmpFirstName +
            ' ' +
            allEmployees[key].fEmpLastName,
          value: allEmployees[key].fEmpId,
          type: 'EMPLOYEE',
        });
      } else {
        results.push(allEmployees[key]);
      }
    }
  });

  return results;
};

export const eventTargetAsHtmlElement = (
  eventTarget: EventTarget,
): HTMLElement | undefined => {
  return !!eventTarget ? (eventTarget as HTMLElement) : undefined;
};

export const currentUserHasTeam = (): boolean => {
  return !!GLOBAL.jsonManagerTeam && GLOBAL.jsonManagerTeam.length > 0;
};

export const lookForSubUnits = (unitsAndEmployees: any[]) => {
  let subUnits: number[] = [];
  if (
    unitsAndEmployees &&
    (unitsAndEmployees[unitsAndEmployees.length - 1] || {}).type === 'UNIT'
  ) {
    subUnits =
      unitsAndEmployees.length === 0
        ? []
        : getSubUnitIds(
            parseInt(
              (unitsAndEmployees[unitsAndEmployees.length - 1] || {}).value,
              10,
            ),
          );
  } else {
    subUnits = [];
  }
  return subUnits;
};

export const getSubUnitIds = (
  unitId: number,
  includeThisUnit: boolean = false,
) => {
  const subUnits = GLOBAL.jsonPrimaryOrgTree.filter(
    (unit: any) => parseInt(unit.fTreeParentUnitId, 10) === unitId,
  );
  let subUnitIds: number[] = subUnits.map((unit: any) =>
    parseInt(unit.fTreeUnitId, 10),
  );
  subUnitIds.forEach((unit: number) => {
    subUnitIds = subUnitIds.concat(getSubUnitIds(unit));
  });

  if (includeThisUnit) {
    subUnitIds.push(unitId);
  }

  return subUnitIds;
};

export const getIncentiveShortTypes = () => {
  return GLOBAL.jsonEnumALL.INCENTIVE_SHORT_TYPE || [];
};

export const getCustomDataGroupName = (groupName: string, code: string) =>
  `__CUSTOMDATA__${groupName.replace('CUSTOM_ENTITY_', '')}__${code}`;

export const getCustomDataParentGroupName = (groupName: string) =>
  `CUSTOM_ENTITY_${groupName.split('__')[2]}`;

export const getEmployeeInfoById = (id: number) => {
  if (id === -1 || !GLOBAL.jsonAllEmp[id]) {
    return '';
  }
  return GLOBAL.jsonAllEmp[id];
};

export const getAllEmployees = (): { [key: number]: EmployeeSummaryData } => {
  return GLOBAL.jsonAllEmp;
};

export const getAllActiveEmployees = (includeMyIndirectTeams?: boolean) => {
  let unitsEmployeesList: any[] = [];

  // Include all the employees from my units
  getMyTeams(includeMyIndirectTeams).forEach(unit => {
    unitsEmployeesList.push(...getUnitMembers(parseInt(unit.value, 10), true));
  });

  // Include the remaining employees for which I am manager
  if (includeMyIndirectTeams) {
    Object.values(GLOBAL.jsonAllEmp).forEach((emp: any) => {
      if (
        emp.fEmpManagerId === GLOBAL.iLoginId &&
        !unitsEmployeesList.includes(emp.fEmpId)
      ) {
        unitsEmployeesList.push({
          label: emp.fEmpFirstName + ' ' + emp.fEmpLastName,
          value: emp.fEmpId,
          type: 'EMPLOYEE',
        });
      }
    });
  }

  return unitsEmployeesList;
};

export const showEmployee = (
  empId: number,
  forceToPeoplePage: boolean = false,
) => {
  GLOBAL.jsShowEmployee(null, empId, forceToPeoplePage);
};

export const getLoggedUserData = () => GLOBAL.jsonloginData;

export const getLoggedUserId = () => GLOBAL.iLoginId;

export const getEmployeeManagerId = (empId: number) => {
  return GLOBAL.jsonAllEmp[empId].fEmpManagerId;
};

const getHRIdForUnit = (unitId: number): any => {
  if (unitId === -1) {
    return null;
  }

  const unit = GLOBAL.jsonOrgUnitData.find((u: any) => u.fOrgUnitId === unitId);
  if (!unit) {
    return null;
  }

  const respEmps = unit.fOrgUnitResponsibleEmps;
  const respHrAdminId = (respEmps.HR_ADMIN && respEmps.HR_ADMIN[0]) || null;
  const respHrStaffId = (respEmps.HR_STAFF && respEmps.HR_STAFF[0]) || null;

  if (!!respHrAdminId || !!respHrStaffId) {
    return respHrStaffId || respHrAdminId;
  } else {
    const primaryTreeUnit = GLOBAL.jsonPrimaryOrgTree.find(
      (u: any) => u.fTreeUnitId === unitId.toString(),
    );
    if (!primaryTreeUnit || primaryTreeUnit.fTreeParentUnitId === '#') {
      return null;
    }
    return getHRIdForUnit(parseInt(primaryTreeUnit.fTreeParentUnitId, 10));
  }
};

export const getEmployeeHRId = (empId: number) => {
  return getHRIdForUnit(GLOBAL.jsonAllEmp[empId].fEmpUnit);
};

export const getSelectedEmpId = () => GLOBAL.iEmpId;

export const getSelectedEmpContractsData = () => GLOBAL.jsonEmpContractsData;

export const getManagerIdForUnit = (unitId: number) => {
  const unitData = GLOBAL.jsonOrgUnitData.find(
    (org: any) => org.fOrgUnitId === unitId,
  );

  return unitData ? unitData.fOrgUnitManager : -1;
};

export const isBnTrainingCalendar = () => GLOBAL.bnTrainingCalendar;

export const hasManagerRole = (roles: string[]) => {
  const mgr = ['MANAGER', 'DEPUTY_MANAGER', 'ALT_MANAGER'];

  return roles.some(role => mgr.some(m => m === role));
};

export const hasHrRole = (roles: string[]) => {
  const hr = ['HR_ADMIN', 'HR_STAFF', 'HR_DEV'];

  return roles.some(role => hr.some(h => h === role));
};

export const hasDevHrRole = (roles: string[]) =>
  roles.some(role => role === 'HR_DEV');

export const hasHrAdminRole = (roles: string[]) =>
  roles.some(role => role === 'HR_ADMIN');

export const hasHrStaffRole = (roles: string[]) =>
  roles.some(role => role === 'HR_STAFF');

export const hasAnyHrRelatedRole = (roles: string[]) => {
  const hr = [
    'HR_ADMIN',
    'HR_ADMIN_LIMITED',
    'HR_PEOP',
    'HR_PEOP_DEV',
    'HR_INITIAL',
    'HR_STAFF',
    'HR_DEV',
    'HR_AUDITOR',
    'HR_SALARY',
  ];

  return roles.some(role => hr.some(h => h === role));
};

export const hasAltMgrRole = (roles: string[]) =>
  roles.some(role => role === 'ALT_MANAGER');

export const hasRecruiterRole = (roles: string[]) =>
  roles.some(role => role === 'RECRUITER');

export const isOnlyAltMgr = () => {
  const { fLoginRoles } = GLOBAL.jsonloginData;

  return (
    fLoginRoles.indexOf('ALT_MANAGER') >= 0 &&
    fLoginRoles.indexOf('MANAGER') < 0
  );
};

export const checkIfAltMgrUnit = (iOU: any) => {
  var bnIsUnitAltUnit = false;

  if (!isOnlyAltMgr()) {
    bnIsUnitAltUnit = GLOBAL.aMyAltDirUnits.includes(parseInt(iOU, 10));
    // $.inArray(parseInt(iOU, 10), GLOBAL.aMyAltDirUnits) !== -1 ? true : false;
  } else {
    bnIsUnitAltUnit = true;
  }

  return bnIsUnitAltUnit;
};

export const getWorkflowManagerId = () => {
  const { fLoginRoles } = getLoggedUserData();
  const fLoginEmpId = getLoggedUserId();
  const jsonPos = GLOBAL.jsonEmpData.hasOwnProperty('empPosition')
    ? GLOBAL.jsonEmpData.empPosition
    : GLOBAL.jsonEmpData.empPositionPending;

  if (hasAltMgrRole(fLoginRoles)) {
    const bnIsUnitAltUnit = checkIfAltMgrUnit(jsonPos.fInternalPositionUnit);
    return bnIsUnitAltUnit ? fLoginEmpId : jsonPos.fInternalPositionManager;
  }

  return jsonPos.fInternalPositionManager;
};

export const isMyEmp = (id: any) => {
  const { jsonAllEmp, aMyHRUnits } = GLOBAL;

  if (id === getLoggedUserId()) {
    return false;
  }
  const jsonPerson = jsonAllEmp[id];
  const iOrgUnitId = jsonPerson === undefined ? -1 : jsonPerson.fEmpUnit;

  return aMyHRUnits.indexOf(iOrgUnitId) >= 0;
};

export const getWorkflowConfig = (wkFlow: any[], wkFlowName: any) =>
  wkFlow.find(wk => wk.fWorkflowName === wkFlowName);

// BENEFIT_PROPOSAL, ALLOWANCE_PROPOSAL, SALARY_PROPOSAL, JOB_OPENING
export const getWorkflowPermission = (
  strWorkflowName: string,
  workFlowConfigs: any[],
  loginRoles: string[],
  empId: any,
  loggedUserId: any,
) => {
  const { jsonEmpData, strManager } = GLOBAL;
  const iUnit = jsonEmpData.hasOwnProperty('empPosition')
    ? jsonEmpData.empPosition.fInternalPositionUnit
    : jsonEmpData.empPositionPending.fInternalPositionUnit;
  const bnIsUnitAltUnit = checkIfAltMgrUnit(iUnit);

  var json = getWorkflowConfig(workFlowConfigs, strWorkflowName);
  let aValues: any[] = ['', '0_NONE'];
  if (strWorkflowName === 'JOB_OPENING') {
    if (hasHrRole(loginRoles)) {
      aValues = ['HR', json.fWorkflowHrCapability];
    } else {
      aValues = ['DM', json.fWorkflowMgrCapability];
    }
  } else if (
    empId === loggedUserId &&
    json.fWorkflowEmpCapability === '1_INITIATE'
  ) {
    aValues = ['EMP', '1_INITIATE'];
  } else if (
    hasHrRole(loginRoles) &&
    hasHrAdminRole(loginRoles) &&
    !hasDevHrRole(loginRoles)
  ) {
    aValues = ['HR', json.fWorkflowHrCapability];
  } else if (hasManagerRole(loginRoles)) {
    if (
      strManager === 'DIRECT' ||
      (hasAltMgrRole(loginRoles) && bnIsUnitAltUnit)
    ) {
      aValues = ['DM', json.fWorkflowMgrCapability];
    } else if (strManager === 'INDIRECT') {
      aValues = ['HM', json.fWorkflowHigherMgrCapability];
    }
  }
  aValues.push(aValues[1] === '1_INITIATE' || aValues[1] === '4_ADMINISTER');

  return aValues;
};

export const findFirstHROfUnit = (iOrgUnitId: any) => {
  let aFirstHR = [];
  let i = 0;
  const { jsonPrimaryOrgTree } = GLOBAL;
  let jsonOU = jsonPrimaryOrgTree.find(
    (org: any) => org.fTreeUnitId === iOrgUnitId.toString(),
  );
  while (aFirstHR.length === 0 && jsonOU !== undefined && i < 20) {
    aFirstHR = jsonOU.fTreeResponsibleHrEmps;
    jsonOU = jsonPrimaryOrgTree.find(
      (o: any) => o.fTreeUnitId === jsonOU.fTreeParentUnitId,
    );
    i++;
  }

  return aFirstHR ? aFirstHR[0] : -1;
};

export const getBonusWorkflowStatus = (
  strRole: string,
  strCapa: string,
  loggedHR: boolean,
) => {
  let strStatus = 'PROPOSED';

  if (strRole === 'DM') {
    if (strCapa !== '1_INITIATE') {
      strStatus = 'MANAGER_APPROVED';
    }
  } else if (strRole === 'HM') {
    strStatus = 'MANAGER_APPROVED';
  } else if (strRole === 'HR') {
    strStatus = 'HR_APPROVED';
  }

  if (loggedHR) {
    strStatus = 'HR_APPROVED';
  }

  return strStatus;
};

export const downloadAttachment = (url: string) => {
  GLOBAL.jsGetAttachment(url);
};

export const getLoggedUserCountry = () => {
  return GLOBAL.getCountryByLegalEntity(
    GLOBAL.jsIsEmpStatus('NEW')
      ? GLOBAL.jsonEmpData.empPositionPending.fEmpLegalEntity
      : GLOBAL.jsonEmpData.empPosition.fEmpLegalEntity,
  );
};

export const userHasApprovedAbsence = (date: string) => {
  return (
    GLOBAL.jsonAbsences.absenceShorts.some((vacation: any) => {
      if (
        vacation.fAbsenceShortStatus !== 'APPROVED' ||
        vacation.hasOwnProperty('fAbsenceShortSupersededTime')
      ) {
        return false;
      }

      return (
        vacation.fAbsenceShortStartDate <= date &&
        vacation.fAbsenceShortEndDate >= date
      );
    }) ||
    GLOBAL.jsonAbsences.absenceLongs.some((vacation: any) => {
      if (
        vacation.fAbsenceLongStatus !== 'APPROVED' ||
        vacation.hasOwnProperty('fAbsenceLongSupersededTime')
      ) {
        return false;
      }

      return (
        vacation.fAbsenceLongStartDate <= date &&
        vacation.fAbsenceLongEndDate >= date
      );
    })
  );
};

export const getUserEmpPositionData = (checkPending?: boolean) => {
  const isEmpStatus = (strStatus: string) =>
    GLOBAL.jsonEmpData.empBasicData.fEmpStatus === strStatus;
  const usePending =
    isEmpStatus('NEW') || (!!checkPending && isEmpStatus('PENDING'));
  return usePending
    ? GLOBAL.jsonEmpData.empPositionPending
    : GLOBAL.jsonEmpData.empPosition;
};

export const getUserLegalEntityId = (checkPending?: boolean) =>
  getUserEmpPositionData(checkPending).fEmpLegalEntity;

export const toEUDate = (strISODate: string) =>
  moment(strISODate, 'YYYY-MM-DD').format('DD.MM.YYYY');

export const toISODate = (strEUDate: string) =>
  moment(strEUDate, 'DD.MM.YYYY').format('YYYY-MM-DD');

export const getUserOriginalStartDate = () =>
  GLOBAL.jsonEmpData.empBasicData.hasOwnProperty('fEmpOrigStartDate')
    ? GLOBAL.jsonEmpData.empBasicData.fEmpOrigStartDate
    : undefined;

export const getUserEmploymentStartDate = () =>
  GLOBAL.jsonEmploymentData[0].fEmplDateStart;

export interface CostCenter {
  fCostCenterCode: string;
  fCostCenterId: number;
  fCostCenterName: string;
}
export const getCostCenter = (id: number) =>
  GLOBAL.jsonCostCenters.find((cc: any) => cc.fCostCenterId === id);

export interface ColumnConfig {
  field: string;
  [key: string]: any;
}