import { Form, Formio } from 'formiojs';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import { compose } from 'recompose';

import { CircularProgress, Grid, Typography } from '@material-ui/core';
import Warning from '@material-ui/icons/Warning';

import API from '@/app/api/internalAPIs';
import { fetchMyDetails, UserDetails } from '@/app/redux/currentUser';
import { ReducerState } from '@/app/redux/store';
import Service from '@/app/utils/service';
import translate from '@/app/utils/translate';
import { hasHrAdminRole, hasHrStaffRole, hasRecruiterRole } from '@/old/utils/helper';

import {
  AllowanceProposalToFormsFormat,
  BenefitProposalToFormsFormat,
  fetchAllFormTemplates,
  fetchEmpAllowanceProposals,
  fetchEmpBenefitProposals,
  fetchLatestTemplateVersion,
  fetchNewEmployeeId,
  fetchProvisionalEmployeeSubmissionData,
  getFormioFriendlyCurrentLanguage,
  overrideDefaultTranslations,
} from '../common';
import { cssStyling } from '../components/CustomBuilder/css';
import { applyCustomFormioConfig } from '../components/CustomBuilder';

import {
  AllowanceProposalItem,
  BenefitProposalItem,
  FormioSubmitData,
  FormioTranslations,
  FormSubmission,
  GridTemplateListItem,
} from '../types';
import { useBootstrapJs } from '../useBootstrapJs';
import { FormTemplateSelection } from './components/FormTemplateSelection';
import { FormioFormBuilderConfig } from '../components/CustomBuilder/types';
import { useFormsCustomConfig } from '@/app/hooks/useFormsCustomConfig';
import { useWorkflowConfig } from '@/app/hooks/useWorkflowConfig';
import { AllEnums } from '@/app/redux/enums';
import { useStyles } from './useStyles';
import TemplateUpdateIconBtns from '../components/TemplateUpdateIconBtns';

applyCustomFormioConfig();

const GLOBAL: any = window;
type MapDispatchToProps = {
  fetchUserDetails: () => any;
};

type MapStateToProps = {
  myDetails: UserDetails;
};

type Props = MapStateToProps & MapDispatchToProps;

const redirectToProvisionalEmployees = (empId: number) => {
  localStorage.setItem('recruitmentPagePreselectedTab', 'provisionalEmployees');
  GLOBAL.parent.location.replace(`/page-recruiting.html?emp=${empId}`);
};

const redirectToNewEmployee = (
  submitData: FormioSubmitData,
  roles: string[],
  retries: number = 3,
) =>
  setTimeout(() => {
    fetchNewEmployeeId(`${submitData.data.ssn}`).then((empId: number) => {
      try {
        if (!empId && retries > 0) {
          redirectToNewEmployee(submitData, roles, retries - 1);

          return;
        }

        return hasHrAdminRole(roles) || hasHrStaffRole(roles) 
          ? GLOBAL.parent.location.replace(`/page-people.html?emp=${empId}`)
          : redirectToProvisionalEmployees(empId);
      } catch {
        return;
      }
    });
  }, 1200); // no way of knowing when emp was added to the system at the moment

Formio.setBaseUrl(`${window.location.origin}/api/forms`);

const NewEmployment = ({ myDetails, fetchUserDetails }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [formTemplates, setFormTemplates] = useState<GridTemplateListItem[]>();
  const [subData, setSubData] = useState<
    Pick<FormSubmission, 'data'> | undefined
  >();
  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  const [formTranslationData, setFormTranslationData] = useState<
    FormioTranslations
  >();
  const [formRootEl, setFormRootEl] = useState<HTMLElement>();
  const [formConfigData, setFormConfigData] = useState<FormioFormBuilderConfig>();
  const [customDataSubmission, setCustomDataSubmission] = useState<{[key: string]: string}>();
  const [benefitProposalsList, setBenefitProposalsList] = useState<BenefitProposalItem[]>([]);
  const [allowanceProposalsList, setAllowanceProposalsList] = useState<AllowanceProposalItem[]>([]);
  const [allEnums, setAllEnums] = useState<AllEnums>({});
  const [pendingEmploymentId, setPendingEmploymentId] = useState<number>();
  const [latestVersion, setLatestVersion] = useState<string>();
  const { pendingEmp, selectedEmp } = useParams();
  const { applyCustomConfig, customDataComponents } = useFormsCustomConfig(selectedTemplateId);

  const workflowConfig = useWorkflowConfig();

  const styles = useStyles();

  const rootElCallbackRef = useCallback(node => {
    if (node) {
      setFormRootEl(node);
    }
  }, []);

  const onVersionUpdated = (data: FormioFormBuilderConfig) => {
    setFormConfigData(applyCustomConfig(data));
  };

  const backToStandardForm = useCallback(() => {
    if (pendingEmp) {
      GLOBAL.parent.jsLoadPage1(
        `newEmp.html?emp=${selectedEmp}&pendingEmp=${pendingEmp}`,
      );
    } else {
      GLOBAL.parent.jsAddNewEmp(false);
    }
  }, [pendingEmp]);

  const handleSelectedTemplate = useCallback(
    (publishedTemplates: GridTemplateListItem[]) => {
      const defaultFormTpl = publishedTemplates.find(
        pf => pf.settings && pf.settings.isDefault,
      );
      setFormTemplates(publishedTemplates);
      setIsLoading(true);
      fetchProvisionalEmployeeSubmissionData(pendingEmp)
        .then(subDataResp => {
          setIsLoading(false);
          if (subDataResp) {
            setSubData(subDataResp);
            return setSelectedTemplateId(subDataResp.form);
          }

          if (defaultFormTpl) {
            return setSelectedTemplateId(defaultFormTpl._id);
          }

          if (publishedTemplates.length) {
            return setSelectedTemplateId(publishedTemplates[0]._id);
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    },
    [
      pendingEmp,
      setIsLoading,
      setSubData,
      setFormTemplates,
      setSelectedTemplateId,
    ],
  );

  useBootstrapJs();

  useEffect(() => {
    fetchUserDetails();

    fetchAllFormTemplates().then((resp: GridTemplateListItem[]) => {
      if (!resp || !resp.length) {
        return setFormTemplates([]);
      }

      const publishedFormsTpls = resp.filter(
        re => re.settings && re.settings.isPublished,
      );
      handleSelectedTemplate(publishedFormsTpls);
    });
  }, [
    fetchUserDetails,
    handleSelectedTemplate,
    setFormTemplates,
  ]);

  useEffect(() => {
    setFormTranslationData(undefined);
    const formioLang = getFormioFriendlyCurrentLanguage();
    Service.get(
      API.forms.getBuilder('employment', formioLang),
      (trResp: any) => {
        if (!trResp || !trResp.i18n) {
          return;
        }

        setFormTranslationData({
          ...trResp,
          language: formioLang,
          i18n: overrideDefaultTranslations(trResp.i18n, formioLang),
        });
      },
      (err: Error) => {
        console.error(err);
      },
    );
  }, [setFormTranslationData]);
  
  useEffect(() => {
    if (selectedTemplateId && customDataComponents) {
      Service.get(
        API.forms.formGeneral(selectedTemplateId),
        (apiResp: FormioFormBuilderConfig) => {
          const formConfig = applyCustomConfig(apiResp);
          setFormConfigData(formConfig);
        },
        (err: Error) => {
          console.error(err);
        },
      );
    }
  }, [selectedTemplateId, customDataComponents]);

  useEffect(() => {
    if (pendingEmp && Object.keys(allEnums).length) {
      Service.get(
        API.employee.customData(pendingEmp),
        (resp: Record<string, string>) => {
          const transformedObj = Object.entries(resp).reduce((acc, [key, val]) => {
            const group = `__CUSTOMDATA__EMPLOYEE__${key}`;
            const enumSubitems = allEnums[group];
            if (!enumSubitems) {
              return { ...acc, [key]: val };
            }
            if (Array.isArray(val)) {
              return {
                ...acc,
                [key]: enumSubitems
                  .filter((item) => val.includes(item.code))
                  .map((item) => {
                    return { code: item.code, name: item.name, group };
                  }),
              };
            }
            const { code, name } = enumSubitems.find((sub) => sub.code === val);
            return { ...acc, [key]: { code, name, group } };
          }, {});
          setCustomDataSubmission(transformedObj);
        },
        (err: Error) => {
          console.error(err);
        },
      );
    }
  }, [pendingEmp, allEnums]);
  
  useEffect(() => {
    if (pendingEmp) {
      Service.get(
        API.enum.all(),
        (apiResp: AllEnums) => {
          setAllEnums(apiResp);
          Service.get(
            API.employee.employment(pendingEmp),
            (resp: Record<string, any>) => {
              setPendingEmploymentId(resp[0].fEmplId);
            },
            (err: Error) => {
              console.error(err);
            },
          );
        },
        (err: Error) => {
          console.error(err);
        },
      );
    }
  }, [pendingEmp]);

  useEffect(() => {
    if (pendingEmploymentId) {
      fetchEmpBenefitProposals(pendingEmp, pendingEmploymentId)
        .then((data: BenefitProposalItem[]) => setBenefitProposalsList(data));
      fetchEmpAllowanceProposals(pendingEmp, pendingEmploymentId)
        .then((data: AllowanceProposalItem[]) => setAllowanceProposalsList(data));
    }
  }, [pendingEmploymentId]);

  useEffect(() => {
    fetchLatestTemplateVersion().then((version) => {
      setLatestVersion(version);
    });
  }, []);

  useEffect(() => {
    let form: Form;
    if (
      formRootEl &&
      formTranslationData &&
      selectedTemplateId &&
      myDetails &&
      formConfigData &&
      workflowConfig
    ) {
      form = new Form(formRootEl, formConfigData, {
        ...formTranslationData,
        language: formTranslationData.language,
        readOnly:
          subData &&
          !hasHrAdminRole(myDetails.fLoginRoles) &&
          !hasHrStaffRole(myDetails.fLoginRoles) &&
          !hasRecruiterRole(myDetails.fLoginRoles),
      });
      form.ready.then((inst: any) => {
        if (subData) {
          inst.submission = {
            ...subData,
            data: {
              ...subData.data,
              ...customDataSubmission,
              allowanceDataGrid: AllowanceProposalToFormsFormat(
                allowanceProposalsList, 
                allEnums,
              ),
              benefitDataGrid: BenefitProposalToFormsFormat(
                benefitProposalsList,
                allEnums,
              ),
            }
          };
        } else {
          inst.submission = {
            data: {
              workflowConfig
            }
          };
        }
        
        inst.triggerChange();

        inst.on('cancel', () => inst.cancel());

        inst.on('goToPreviousPage', () => inst.prevPage());

        inst.on('goToNextPage', () => inst.nextPage());

        // required for older templates that make use of the default buttons
        inst.on('submit', redirectToNewEmployee, false);

        inst.on('customSubmit', (data: any) => {
          inst.submit()
            .then(() => {
              Service.post(
                API.forms.formSubmissions(selectedTemplateId),
                { data },
                () => {
                  redirectToNewEmployee({ data } as FormioSubmitData, myDetails.fLoginRoles);
                },
                (err: Error) => { console.log(err); }
              );
            })
            .catch((err: Error) => {
              console.error(err);
            });
        });
      });
    }

    return () => {
      if (form) {
        form.off('submit');
      }
    };
  }, [
    formRootEl,
    selectedTemplateId,
    formTranslationData,
    myDetails,
    subData,
    customDataSubmission,
    allowanceProposalsList,
    benefitProposalsList,
    formConfigData,
    workflowConfig,
  ]);

  return (
    <>
      <style>{cssStyling}</style>
      <Grid container direction="column" style={styles.formPageContainer}>
        <Grid item md={8} lg={6} style={styles.componentsContainer}>
          <Grid
            className="container-fluid"
            style={styles.container}
          >
            <Grid item>
              {!pendingEmp ? (
                <FormTemplateSelection
                  selectedTemplateId={selectedTemplateId}
                  formTemplates={formTemplates}
                  onFormTemplateChange={setSelectedTemplateId}
                />
              ) : null}
              {pendingEmp && !subData && !isLoading ? (
                <Typography style={styles.messageParagraphs}>
                  {translate.t('laPendingEmpNotCompatible')}
                </Typography>
              ) : null}
              {pendingEmp && subData && !isLoading ? (
                <Typography
                  variant="caption"
                  style={styles.messageParagraphs}
                >
                  <Warning color="secondary" />
                  {translate.t('laPendingEmpWarning')}
                </Typography>
              ) : null}
              <span
                onClick={backToStandardForm}
                className="badge badge-info"
                style={styles.backToStandard}
              >
                {translate.t('laNewEmpFormsBack')}
              </span>
            </Grid>
            {
              latestVersion && formConfigData && (
                <TemplateUpdateIconBtns
                  updated={formConfigData.settings.version === latestVersion}
                  formId={formConfigData._id}
                  disableAction={!hasHrAdminRole(myDetails.fLoginRoles)}
                  onVersionUpdated={onVersionUpdated}
                />
              )
            }
          </Grid>
        </Grid>
        {!formTemplates && !Array.isArray(formTemplates) ? (
          <Grid container item md={8} lg={6} justify="center">
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : (
          <Grid item md={8} lg={6}>
            <div className="container-fluid">
              <div ref={rootElCallbackRef} />
            </div>
          </Grid>
        )}
      </Grid>
    </>
  );
};

const mapStateToProps = (state: ReducerState) => {
  return { myDetails: state.currentUser.get('myDetails') };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchUserDetails: () => dispatch<any>(fetchMyDetails()),
});

const enhance = compose<Props, {}>(
  connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(NewEmployment);
