import { useEffect, useState } from 'react';
import Service from '@/app/utils/service';
import API from '@/app/api/internalAPIs';
import { RewardingProposalType, WorkflowConfig } from '../pages/EmploymentForms/types';

const GLOBAL: any = window;
const isHRAdmin = GLOBAL.top.iHRAdmin;
const isManager = GLOBAL.top.iManager;

export const useWorkflowConfig = () => {
  const [workflowSettings, setWorkflowSettings] = useState<WorkflowConfig[]>([]);

  useEffect(() => {
    Service.get(
      API.workflow.config(),
      (apiResp: WorkflowConfig[]) => {
        setWorkflowSettings(apiResp);
      },
      (err: Error) => {
        console.error(err);
      },
    );
  }, []);

  return (
    workflowSettings.length 
      ? workflowSettings.reduce((acc, wfl) => {
          const canAdd = (wfl.fWorkflowMgrCapability === '1_INITIATE' && isManager) || isHRAdmin;
          return { ...acc, [wfl.fWorkflowName]: canAdd };
        }, {} as Record<RewardingProposalType, boolean>)
      : null
  );
};