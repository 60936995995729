import { withStyles, WithStyles } from '@material-ui/core';
import { AssignmentTurnedIn } from '@material-ui/icons';
import { AxiosResponse } from 'axios';
import { Formio } from 'formiojs';
import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { RouteComponentProps } from 'react-router';
import { compose } from 'recompose';
import moment from 'moment';

import API from '@/app/api/internalAPIs';
import EnhancedTable from '@/app/components/EnhancedTable/EnhancedTable';
import { HeadData } from '@/app/components/EnhancedTable/EnhancedTableHead/EnhancedTableHead';
import { Tools } from '@/app/components/EnhancedTable/EnhancedTableToolbar/EnhancedTableToolbar';
import { HOMEPAGE } from '@/app/utils/helper';
import translate from '@/app/utils/translate';
import Service from '@/app/utils/service';

import {
  EmployeeBasicData,
  GridTemplateListItem,
  TemplateForm,
  TemplateListItem,
} from './types';
import { styles } from './styles';
import {
  fetchAllFormTemplates,
  fetchFormSubmissions,
  fetchLatestTemplateVersion,
  fetchPendingEmployees,
  normalizeString,
} from './common';
import TemplateUpdateIconBtns from './components/TemplateUpdateIconBtns';

const GLOBAL: any = window;
if (typeof GLOBAL._ !== 'undefined') {
  GLOBAL.lodash = GLOBAL._.noConflict();
}

Formio.setBaseUrl(`${window.location.origin}/api/forms`);

const TOOLS: Tools = {
  showActive: true,
  showAdd: true,
  showEdit: true,
  showDelete: true,
  // showTranslate: true,
  showDuplicate: true,
};

const updateFormTemplate = (
  templateId: string,
  changes: Partial<GridTemplateListItem>,
) =>
  new Promise((resolve, reject) => {
    Service.put(
      API.forms.formGeneral(templateId),
      {
        ...changes,
      },
      (resp: TemplateForm) => {
        resolve(resp);
      },
      (err: AxiosResponse) => {
        reject(err);
      },
    );
  });

type Props = WithStyles<typeof styles> & RouteComponentProps;

const EmploymentForms = (props: Props) => {
  const { classes, history } = props;
  const [formTemplates, setFormTemplates] = useState<TemplateListItem[]>([]);
  const [pendingEmployees, setPendingEmployees] = useState<EmployeeBasicData[]>(
    [],
  );
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [latestVersion, setLatestVersion] = useState<string>();
  const [templatesLoading, setTemplatesLoading] = useState<boolean>();

  const onVersionUpdated = (updatedTemplate: TemplateListItem) => {
    const index = formTemplates.findIndex(tmpl => tmpl._id === updatedTemplate._id);
    const temp = [...formTemplates];
    temp.splice(index, 1, updatedTemplate);
    setFormTemplates(temp);
  };

  const empNumbers = useMemo(
    () => pendingEmployees.map(pe => pe.fEmpPersonNumber),
    [pendingEmployees],
  );
  const headData: Array<HeadData> = [
    {
      id: 'subject',
      type: 'string',
      disablePadding: false,
      label: translate.t('laSubject'),
    },
    {
      id: 'status',
      type: 'string',
      disablePadding: false,
      label: translate.t('laStatus'),
    },
    {
      id: 'lastEdit',
      type: 'string',
      disablePadding: false,
      label: translate.t('laLastEdited'),
    },
    {
      id: 'default',
      type: 'string',
      disablePadding: false,
      label: translate.t('laDefault'),
    },
    {
      id: 'versionStatus',
      type: 'icon',
      disablePadding: false,
      label: translate.t('fTemplateVersionStatus'),
      className: 'fa fa-download',
      renderCustomDataCell: (templateData) => {
        return (
          <TemplateUpdateIconBtns
            updated={!templatesLoading && templateData.settings.version === latestVersion}
            formId={templateData._id}
            loading={templatesLoading}
            onVersionUpdated={onVersionUpdated}
          />
        );
      },
    },
  ];

  const tableData = formTemplates.map(template => ({
    ...template,
    active: !!(template.settings && template.settings.isPublished),
    default:
      template.settings && template.settings.isDefault
        ? translate.t('laYes')
        : '',
    id: template._id,
    isDefault: !!(template.settings && template.settings.isDefault),
    subject: template.title,
    status:
      template.settings && template.settings.isPublished
        ? translate.t('laActive')
        : translate.t('laInactive'),
    lastEdit: `${moment(template.modified).format('D.M.YYYY')}`,
  }));

  const fetchForms = useCallback(() => {
    setTemplatesLoading(true);
    fetchAllFormTemplates().then((resp: TemplateListItem[]) => {
      setFormTemplates(resp);
    })
    .finally(() => setTemplatesLoading(false));
  }, [setFormTemplates]);

  const makeTemplateDefault = (templateId: string) => {
    formTemplates.forEach(tpl => {
      if (!tpl.settings || !tpl.settings.isPublished) {
        return;
      }

      if (tpl._id === templateId) {
        updateFormTemplate(tpl._id, {
          settings: { ...tpl.settings, isDefault: true },
        }).then(() => {
          fetchForms();
        });

        return;
      }

      if (tpl.settings && tpl.settings.isDefault) {
        updateFormTemplate(tpl._id, {
          settings: { ...tpl.settings, isDefault: false },
        }).then(() => {
          fetchForms();
        });

        return;
      }
    });
  };

  const renderMakeDefaultMenuOption = (rowData: GridTemplateListItem) => {
    if (rowData.default || !rowData.active) {
      return [];
    }

    return [
      {
        onClick: () => {
          makeTemplateDefault(rowData.id);
        },
        label: translate.t('laDefault'),
        iconButton: <AssignmentTurnedIn />,
      },
    ];
  };

  useEffect(() => {
    fetchForms();
    fetchPendingEmployees().then((resp: EmployeeBasicData[]) => {
      setPendingEmployees(resp);
    });
  }, [fetchForms, setFormTemplates]);

  useEffect(() => {
    fetchLatestTemplateVersion().then((versionData) => {
      setLatestVersion(versionData);
    });
  }, []);

  const togglePublishStatusHandler = (publish: boolean) => (
    selected: GridTemplateListItem[],
  ) => (_event: MouseEvent) => {
    const toggled = selected
      .map(selectedItem => {
        const id = selectedItem._id || '';
        const found = id
          ? formTemplates.find(fTpl => fTpl._id === id)
          : undefined;
        if (!id || !found || selectedItem.isDefault) {
          return undefined;
        }

        return fetchFormSubmissions(id).then(subs => {
          const foundPending = subs.find(sub =>
            empNumbers.includes(`${sub.data.employeeNumber}`),
          );
          if (foundPending) {
            return undefined;
          }

          return updateFormTemplate(id, {
            settings: {
              ...found.settings,
              isPublished: publish,
            },
          });
        });
      })
      .filter(potentialPromise => !!potentialPromise);

    Promise.all(toggled).finally(() => {
      fetchForms();
    });
  };

  const addHandler = () => {
    history.push(`${HOMEPAGE}/isolated/form/builder/employment/new`);
  };

  const editHandler = (selected: GridTemplateListItem[]) => (
    _event: MouseEvent,
  ) => {
    const id = selected && selected.length ? selected[0]._id : '';
    if (!id) {
      return;
    }

    history.push(
      `${HOMEPAGE}/isolated/form/builder/employment/${selected[0]._id}`,
    );
  };

  const deleteHandler = (selected: GridTemplateListItem[]) => (
    _event: MouseEvent,
  ) => {
    const deleting = selected
      .map(selectedItem => {
        const id = selectedItem._id || '';
        if (!id || selectedItem.isDefault) {
          return undefined;
        }

        return fetchFormSubmissions(id).then(subs => {
          const foundPending = subs.find(sub =>
            empNumbers.includes(`${sub.data.employeeNumber}`),
          );
          if (foundPending) {
            return undefined;
          }

          return Service.delete(
            API.forms.formGeneral(id),
            undefined,
            () => '',
            () => '',
          );
        });
      })
      .filter(potentialPromise => !!potentialPromise);

    if (!deleting.length) {
      return;
    }

    Promise.all(deleting).finally(() => {
      fetchForms();
    });
  };

  const genereateNewFormDuplicate = (
    formTemplateDetails: TemplateForm,
    index: number = 0,
  ) => {
    if (!formTemplateDetails) {
      return;
    }

    if (index > 30) {
      setIsModalVisible(true);
      setTimeout(() => {
        setIsModalVisible(false);
      }, 10000);
      return;
    }

    let title = `${formTemplateDetails.title} - ${translate.t(
      'label_duplicate',
    )}`;
    if (index > 0) {
      title += ` ${index}`;
    }
    const name = normalizeString(title.replace(/\s+/g, '-').toLowerCase());
    const path = `employment/${name}`;
    const { _id, machineName, ...minResp } = formTemplateDetails;

    let reqBody = {
      ...minResp,
      settings: {
        category: 'employment',
        builder: 'employment',
        isPublished: false,
        isDefault: false,
      },
      title,
      name,
      path,
    };

    Service.post(
      API.forms.createForm(),
      reqBody,
      (duplicatedForm: any) => {
        return setFormTemplates([...formTemplates, duplicatedForm]);
      },
      (err: any) => {
        if (
          err &&
          err.data &&
          err.data.errors &&
          (err.data.errors.includes('The Name must be unique per Project.') ||
            err.data.errors.includes('The Path must be unique per Project.'))
        ) {
          genereateNewFormDuplicate(formTemplateDetails, index + 1);
        }
      },
    );
  };

  const duplicateHandler = (selected: GridTemplateListItem[]) => (
    _event: MouseEvent,
  ) => {
    const formId = selected && selected.length ? selected[0]._id : '';
    Service.get(
      API.forms.formGeneral(formId),
      (response: any) => {
        if (!response) {
          return;
        }
        genereateNewFormDuplicate(response);
      },
      (err: any) => console.log(err),
    );
  };

  const translateHandler = (_selected: GridTemplateListItem[]) => () => {
    // setSelectedTemplates(selected);
    // setTranslateDialogVisibility(true);
  };

  return (
    <>
    <div className={classes.root}>
      {isModalVisible ? (
        <div
          className={classes.alert}
          onClick={() => {
            setIsModalVisible(false);
          }}
        >
          {`${translate.t('empContractTemplatesDuplicateLimits')}`}
        </div>
      ) : (
        <></>
      )}
      <EnhancedTable
        title={translate.t('laEmpContractTemplates')}
        hideTools={{
          hideDelete: false,
          hideTranslate: false,
        }}
        tools={TOOLS}
        customRowButtons={renderMakeDefaultMenuOption as any}
        activateHandler={togglePublishStatusHandler(true)}
        deactivateHandler={togglePublishStatusHandler(false)}
        addHandler={addHandler}
        editHandler={editHandler}
        deleteHandler={deleteHandler}
        duplicateHandler={duplicateHandler}
        translateHandler={translateHandler}
        headData={headData}
        data={tableData}
        order={'asc'}
        orderBy={'name'}
      />
    </div>
    </>
  );
};

const enhance = compose<Props, {}>(
  withStyles(styles, {
    withTheme: true,
  }),
);

export default enhance(EmploymentForms);
