import React, { useState } from 'react';
import translate from '@/app/utils/translate';
import { CircularProgress, Typography, withStyles, WithStyles } from '@material-ui/core';
import { updateTemplateVersion } from '../../common';
import { useDispatch } from 'react-redux';
import { openNotifier } from '@/app/redux/notifier';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import { compose } from 'recompose';
import { CheckRounded } from '@material-ui/icons';
import { TemplateForm, TemplateListItem } from '../../types';
import { FormioFormBuilderConfig } from '../CustomBuilder/types';

type ComponentProps = {
  updated: boolean;
  formId: string;
  disableAction?: boolean;
  marginLeft?: string;
  loading?: boolean;
  onVersionUpdated?: (updateTemplate: TemplateListItem | TemplateForm | FormioFormBuilderConfig) => void;
};

const styles = () => {
  return {
    downloadButton: {
      color: '#f50057',
      '&:hover': {
        backgroundColor: '#e1005014',
      },
      '&:focus': {
        backgroundColor: '#e1005014',
      },
    },
    loader: {
      height: '48px',
      width: '48px',
      paddingLeft: '10px',
      display: 'flex',
      alignItems: 'center',
    }
  };
};

type Props = ComponentProps & WithStyles<typeof styles>;

const TemplateUpdateIconBtns = (props: Props) => {
  const { updated, formId, disableAction, marginLeft, classes, loading, onVersionUpdated } = props;
  const dispatch = useDispatch();

  const [updating, setUpdating] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState<boolean>();

  const handleClick = () => {
    if (!disableAction) {
      setUpdating(true);
      updateTemplateVersion(formId)
        .then((data) => {
          dispatch(openNotifier());
          setUpdateSuccess(true);
          setUpdating(false);
          if (onVersionUpdated) {
            onVersionUpdated(data as TemplateForm);
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(openNotifier({ type: 'error', message: translate.t('fError_unableToUpdateTemplate') }));
          setUpdateSuccess(false);
          setUpdating(false);
        });
    }
  };

  if (loading || updating) {
    return (
      <span className={classes.loader} style={{ marginLeft }}>
        <CircularProgress size={22} />
      </span>
    );
  }

  if (!updated && disableAction) {
    return (
      <Typography color="error" title="fTemplateOutdatedInfo">{translate.t('fTemplateOutdated')}</Typography>
    );
  }

  return updated || updateSuccess
    ? (
      <IconButton
        title={translate.t('fTemplateLatest')}
        style={{
          color: '#28a745',
          ...(marginLeft && { marginLeft: '20px' }),
        }}
      >
        <CheckRounded />
      </IconButton>
    )
    : (
      <IconButton
        aria-label="update"
        color="secondary"
        onClick={handleClick}
        title={translate.t('fTemplateOutdated')}
        className={classes.downloadButton}
        style={{
          ...(marginLeft && { marginLeft: '20px' }),
        }}
      >
        <GetAppIcon />
      </IconButton>
    );
};

const enhance = compose<Props, ComponentProps>(
  withStyles(styles),
);

export default enhance(TemplateUpdateIconBtns);