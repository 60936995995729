export const ENABLED_TABS_PEOPLE = 'ENABLED_TABS_PEOPLE';
export const ENABLED_TABS_PERFORMANCE = 'ENABLED_TABS_PERFORMANCE';
export const ENABLED_TABS_DEVELOPMENT = 'ENABLED_TABS_DEVELOPMENT';
export const ENABLED_TABS_REWARDING = 'ENABLED_TABS_REWARDING';
export const ENABLED_TABS_PLANNING = 'ENABLED_TABS_PLANNING';
export const PEOP_BASIC = 'PEOP_BASIC';

export const forTranslation = {
  DEV_COMPETENCIES: 'laCompetencies',
  DEV_EDUCATION: 'laEducation',
  DEV_EXPERIENCE: 'laExperience',
  DEV_PLAN: 'laDevPlan',
  DEV_JOB_REQUIREMENT_EVAL: 'laJobReqEval',
  PLAN_TALENT: 'laTalent',
  PLAN_PERFORMANCE: 'laPerformance',
  PLAN_PERF_EVALUATION: 'laPerformanceEvaluation',
  PLAN_COMPETENCIES: 'laCompetencies',
  PLAN_GOAL_EVALUATION: 'laGoalEvaluation',
  PLAN_APPRAISAL_TEMPL: 'laAppraisalTemplates',
  PEOP_ABSENCES: 'laAbsences',
  PEOP_BASIC: 'laBasicData',
  PEOP_CONTACT: 'laContact',
  PEOP_COLLABORATION: 'laChecklistsAndTemplates',
  PEOP_CUSTOM_DATA: 'laCustomData',
  PEOP_DECISION_REGISTRY: 'laDecisionRegistry',
  PEOP_DEVELOPMENT: 'laDevelopment',
  PEOP_DOCUMENTS: 'laEmpDocs',
  PEOP_EQUIPMENT: 'laComEq',
  PEOP_JOB_DESCRIPTION: 'laPositionCustomData',
  PEOP_PERFORMANCE: 'laPerformance',
  PERF_APPRAISAL: 'laAppraisalTemplates',
  PERF_FEEDBACK: 'laFeedback',
  PERF_GOAL_EVALUATION: 'laGoalEvaluation',
  PERF_GOAL_SETTING_REVIEW: 'laGoalSetting',
  PERF_NOTES: 'laNotes',
  PERF_PERF_EVAL: 'laPerformanceEvaluation',
  PERF_PROCESS_TEMPLATES: 'laProcessTemplates',
  PERF_SELF_EVAL: 'laSelfEvaluation',
  PERF_STATUS_DISC: 'laStatusDisc',
  REW_ACKNOWLEDGEMENTS: 'laAcknowledgements',
  REW_BANK_ACCOUNT: 'laBankAccount',
  REW_BENEFITS: 'laBenefits',
  REW_BILLABLE_HOURS: 'laBillableHours',
  REW_CUSTOM_DATA: 'laCustomData',
  REW_DOCUMENTS: 'laEmpDocs',
  REW_GENERAL: 'laGeneral',
  REW_INCENTIVES: 'laIncentives',
  REW_SUMMARY: 'laSummary',
  REW_TIME_REPORTING: 'laWorktime'
};
